.about-container {
  display: flex;
  justify-content: space-between; /* Distribuye el espacio entre el contenido y la imagen */
  align-items: center; /* Centra verticalmente la imagen */
  flex-direction: row; /* Asegura que el contenido y la imagen estén en línea */
  padding: 20px; /* Añade un padding alrededor de la sección */
}

.content-section {
  width: 50%; /* Ajusta el ancho del contenido */
}

.logo-section2 {
  width: 40%; /* Ajusta el ancho de la imagen */
  display: flex;
  justify-content: center; /* Centra la imagen horizontalmente dentro de su contenedor */
  align-items: center; /* Centra la imagen verticalmente dentro de su contenedor */
}

.logo-section2 img {
  max-width: 100%; /* Asegura que la imagen no exceda su contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .about-container {
      flex-direction: column; /* Cambia a una columna en pantallas más pequeñas */
      align-items: center; /* Centra todo el contenido */
  }

  .content-section,
  .logo-section2 {
      width: 100%; /* Asegura que tanto el contenido como la imagen ocupen el 100% del ancho */
      text-align: center; /* Centra el texto y la imagen */
      margin-top: 50px;
  }

  .logo-section2 img {
      max-width: 80%; /* Limita el ancho de la imagen en pantallas pequeñas */
  }
}
