/* Estilos generales para la cuadrícula de productos */
.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Reduce el espacio entre elementos en pantallas pequeñas */
  margin: 0 auto; /* Centra el contenedor */
  padding: 0 20px; /* Añade padding horizontal */
}

.product-item {
  cursor: pointer;
  border: 1px solid #6b6969;
  padding: 16px;
  text-align: center;
  width: 100%; /* Ocupa el 100% del ancho disponible en pantallas pequeñas */
  max-width: 250px; /* Limita el ancho máximo en pantallas más grandes */
  transition: transform 0.3s;
  position: relative;
  z-index: 1;
}

.product-item img {
  width: 100%; /* Asegura que la imagen ocupe todo el ancho del contenedor */
  height: 200px; /* Establece una altura fija para todas las imágenes */
  object-fit: cover; /* Escala la imagen para cubrir todo el contenedor, recortando si es necesario */
  display: block; /* Elimina el espacio blanco debajo de la imagen */
}

.product-item.hover {
  transform: scale(1.1);
  z-index: 10;
}

.product-description {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #c73906;
  color: #fff;
  padding: 10px;
  text-align: center; /* Centra el texto horizontalmente */
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 15;
  display: flex; /* Activa el modelo de caja flex */
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  /* Ajustar el alto si es necesario para centrar verticalmente */
  height: 100px; /* Ejemplo: Ajusta según tus necesidades */
}


.product-item.hover .product-description {
  visibility: visible;
  opacity: 1;
}

.search-input {
  margin-bottom: 20px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Estilos para el menú de secciones */
.section-menu::-webkit-scrollbar {
  display: none; /* Ocultar barra de desplazamiento en Chrome, Safari y Opera */
}

.section-menu {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  overflow-x: auto;
  white-space: nowrap;
  flex-grow: 1;
  scrollbar-width: none; /* Ocultar barra de desplazamiento en Firefox */
}

.section-button {
  padding: 8px 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  transition: background-color 0.3s;
}

.section-button.active,
.section-button:hover {
  background-color: #f05817;
  color: white;
}

.section-menu-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  position: relative;
}

.scroll-button {
  background-color: #0a0a0a;
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.scroll-button:hover {
  background-color: #f05817;
}

.scroll-button.left {
  order: -1;
}

.products-container {
  padding-bottom: 150px; /* Agrega un espacio al final del contenedor */
}

/* Leyenda fija en el lado izquierdo */
.catalog-legend {
  position: absolute;
  top: 10%;
  right: 10px;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8); /* Fondo semi-transparente para que no estorbe */
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 1em;
  z-index: 1000;
  transition: opacity 0.3s, visibility 0.3s;
  text-align: center; /* Centra el texto horizontalmente */
  display: flex; /* Usa flexbox para centrar el contenido */
  justify-content: center; /* Centra el texto horizontalmente */
  align-items: center; /* Centra el texto verticalmente */
  width: 200px; /* Establece un ancho para el contenedor si es necesario */
}


.catalog-legend.hidden {
  opacity: 0;
  visibility: hidden;
}

/* Estilos para pantallas medianas (tablets) */
@media (max-width: 768px) {
  .product-grid {
    gap: 20px; /* Ajusta el espacio entre elementos */
    padding: 0 10px; /* Ajusta el padding horizontal */
  }

  .product-item {
    width: 100%; /* Ocupa el 100% del ancho en pantallas pequeñas */
    max-width: 100%; /* Asegura que ocupe todo el ancho disponible */
  }

  .search-input {
    padding: 12px; /* Ajusta el padding en dispositivos más pequeños */
  }

  .section-menu {
    gap: 8px; /* Reduce el espacio entre botones en pantallas más pequeñas */
  }

  .section-button {
    font-size: 12px; /* Reduce el tamaño de la fuente en pantallas más pequeñas */
  }

  .catalog-legend {
    font-size: 0.8em; /* Ajusta el tamaño de la fuente */
    top: 5%; /* Ajusta la posición vertical */
    right: 5px; /* Ajusta la posición horizontal */
  }
}

/* Estilos para pantallas pequeñas (móviles) */
@media (max-width: 480px) {
  .product-grid {
    gap: 10px; /* Reduce el espacio entre elementos */
    padding: 0 5px; /* Ajusta el padding horizontal */
  }

  .product-item {
    max-width: 100%; /* Asegura que ocupe todo el ancho disponible */
  }

  .search-input {
    padding: 10px; /* Ajusta el padding en dispositivos móviles */
  }

  .section-button {
    font-size: 10px; /* Reduce aún más el tamaño de la fuente */
  }

  .catalog-legend {
    font-size: 0.4em; /* Ajusta el tamaño de la fuente */
    top: 80%; /* Ajusta la posición vertical */
    right: 20px; /* Ajusta la posición horizontal */
  }
}
