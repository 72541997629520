.services-container {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 100px; /* Add bottom margin to push the footer down */
  }
  
  .services-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .service-panel {
    background-size: cover;
    background-position: center;
    color: white;
    margin-bottom: 20px;
    padding: 20px;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
    max-height: 60px; /* Adjust initial height as needed */
    padding-bottom: 5px; /* Adjust padding for title positioning */
    opacity: 0.7;
    text-shadow: 1px 1px 2px black; /* Adds a subtle shadow to all text */
  }
  .service-panel:hover {
    opacity: 0.8; /* Ajusta la opacidad cuando se pasa el cursor */
}


.service-content {
    margin-top: 20px;
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 5px;
    max-height: 500px; /* Adjust max height for content visibility */
    overflow-y: auto; /* Add scroll for long content */
    color: white; /* Ensure the text color is white */
    text-shadow: 1px 1px 2px black; /* Adds a subtle shadow to all text */
}

  
  .service-panel.open {
    max-height: 600px; /* Adjust expanded height as needed */
    padding-bottom: 20px; /* Adjust padding for expanded content */
    overflow-y: auto; /* Add scroll for long content */
  }
  
  .service-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: -5px; /* Adjust position for title visibility */
  }
  
  .toggle-arrow {
    font-size: 20px;
  }
  
  .service-content {
    margin-top: 20px;
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 5px;
    max-height: 500px; /* Adjust max height for content visibility */
    overflow-y: auto; /* Add scroll for long content */
  }
  
  .service-content ul {
    padding-left: 20px;
  }
  