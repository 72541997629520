.whatsapp-float {
    position: fixed;
    bottom: 20px;
    right: 20px;
    margin-bottom: 50px;
    z-index: 9999;
}
  
.whatsapp-float a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    color: white;
    font-size: 30px;
}
  
.whatsapp-float a:hover {
    background-color: #22bb58;
    text-decoration: none;
}
  
.whatsapp-icon {
    color: white;
}
