/* styles.css */
html, body {
    margin: 0;
    height: 100%; /* Asegura que el body ocupe toda la altura del viewport */
}

body {
    display: flex;
    flex-direction: column;
}

.container {
    flex: 1; /* Ocupa el espacio restante del viewport */
    display: flex;
    flex-direction: column;
    padding-bottom: 80px; /* Espacio para el footer */
}

.map-container {
    position: relative;
    width: 100%;
    height: 60vh; /* Ajusta según el espacio que quieras para el mapa */
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px; /* Asegura que haya suficiente espacio entre el mapa y el footer */
}

footer {
    background-color: #f8f9fa;
    padding: 10px;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: relative; /* O usa 'fixed' si deseas que esté siempre en la parte inferior */
    bottom: 0;
}

.whatsapp-float {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .whatsapp-float a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    color: white;
    font-size: 30px;
  }
  
  .whatsapp-float a:hover {
    background-color: #22bb58;
    text-decoration: none;
  }
  
  .whatsapp-icon {
    color: white;
  }
  