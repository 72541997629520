body {
  font-family: Arial, sans-serif;
}

.container {
  margin-top: 20px;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
