/* Estilos generales para escritorio */
.about-container {
  display: flex;
  height: 80vh; /* Ocupa el 80% de la altura de la pantalla */
}

.content-section {
  flex: 1; /* Ocupa el 50% del ancho */
  padding: 20px;
  overflow-y: auto; /* Permite desplazamiento si el contenido es largo */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra verticalmente */
}

.logo-section2 {
  flex: 0.7; /* Ocupa el 70% del ancho */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff; /* Fondo blanco */
  margin-top: 135px;
}

.logo {
  max-width: 150%;
  max-height: 150%;
  object-fit: contain;
}

.panels {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.panel {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.panel:hover {
  background-color: #e0e0e0;
}

.panel h2 {
  margin: 0;
}

.panel p {
  margin: 10px 0 0;
}

/* Estilos para pantallas medianas (tablets, pantallas de menos de 768px) */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column; /* Cambia a una columna */
    height: auto; /* Deja que la altura sea automática */
  }

  .logo-section2, .content-section {
    flex: 1; /* Ocupan el 100% del ancho */
    margin-top: 20px; /* Elimina el margen superior del logo */
    padding: 10px; /* Reduce el padding */
  }

  .logo {
    max-width: 100%; /* Asegura que la imagen se ajuste bien */
    max-height: 100%;
  }
}

/* Estilos para pantallas pequeñas (móviles, pantallas de menos de 480px) */
@media (max-width: 480px) {
  .about-container {
    padding: 10px; /* Añade un poco de padding alrededor del contenedor */
  }

  .content-section {
    padding: 10px;
  }

  .logo-section2 {
    padding: 10px;
    margin-top: 0; /* Elimina el margen superior */
  }

  .panels {
    gap: 5px; /* Reduce el espacio entre los paneles */
    margin-top: 10px; /* Reduce el margen superior */
  }

  .panel {
    padding: 8px; /* Reduce el padding de los paneles */
  }

  .panel h2 {
    font-size: 1.2em; /* Ajusta el tamaño del texto del encabezado */
  }

  .panel p {
    font-size: 0.9em; /* Ajusta el tamaño del texto del párrafo */
  }
}

/* Estilos para el menú de navegación */
.navbar-custom {
  background-color: transparent;
  border-bottom: none;
  height: calc(2.5rem * 1.4); /* Aumenta la altura del Navbar en un 40% */
  display: flex;
  align-items: center; /* Centra verticalmente los elementos dentro del Navbar */
  justify-content: space-between; /* Distribuye espacio entre los elementos */
  padding: 0 20px; /* Añade padding horizontal */
}

.navbar-brand {
  margin: 0; /* Elimina el margen en la marca del Navbar */
  padding: 0; /* Elimina el padding en la marca del Navbar */
  flex-grow: 1; /* Asegura que el espacio entre el logo y los enlaces de navegación se distribuyan correctamente */
  display: flex;
  justify-content: flex-end; /* Alinea el logo a la derecha */
}

.logo-image {
  max-height: 40px; /* Ajusta la altura máxima del logo */
  width: auto; /* Mantiene la proporción del logo */
}

.nav-link-custom {
  position: relative;
  color: #5b9bd5; /* Azul suave inicial */
  overflow: hidden;
  transition: color 0.3s ease;
}

.nav-link-custom::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: red; /* Color de fondo de la animación */
  transition: left 0.5s ease;
  z-index: 0;
}

.nav-link-custom:hover::before {
  left: 0;
}

.nav-link-custom:hover {
  color: white !important; /* Cambia a blanco al pasar el mouse */
}

.nav-link-custom span {
  position: relative;
  z-index: 1;
}

/* Estilo para el submenú */
.navbar-custom .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco semi-transparente */
  border: none; /* Eliminar el borde */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Añadir una sombra suave */
  transition: background-color 0.3s ease;
}

/* Si quieres un fondo completamente sólido */
.navbar-custom .dropdown-menu.solid-background {
  background-color: #fff; /* Fondo blanco sólido */
}

/* Ajustes para el menú en dispositivos móviles */
@media (max-width: 768px) {
  .navbar-custom {
    height: auto; /* Ajusta la altura para dispositivos móviles */
  }

  .navbar-brand,
  .nav-link-custom {
    font-size: calc(1rem * 1.2); /* Ajusta el tamaño de la fuente en dispositivos móviles */
  }

  .nav-link-custom::before {
    background-color: red; /* Mantiene el color de fondo de la animación */
  }
}


.nav-link-custom:hover {
  color: white !important; /* Cambia a blanco al pasar el mouse */
}

.nav-link-custom span {
  position: relative;
  z-index: 1;
}

/* Estilo para el submenú */
.navbar-custom .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco semi-transparente */
  border: none; /* Eliminar el borde */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Añadir una sombra suave */
  transition: background-color 0.3s ease;
}

/* Si quieres un fondo completamente sólido */
.navbar-custom .dropdown-menu.solid-background {
  background-color: #fff; /* Fondo blanco sólido */
}

/* Ajustes para el menú en dispositivos móviles */
@media (max-width: 768px) {
  .navbar-custom {
    height: auto; /* Ajusta la altura para dispositivos móviles */
  }

  .navbar-brand,
  .nav-link-custom {
    font-size: calc(1rem * 1.2); /* Ajusta el tamaño de la fuente en dispositivos móviles */
  }

  .nav-link-custom::before {
    background-color: red; /* Mantiene el color de fondo de la animación */
  }
}

.nav-link-custom:hover::before {
  left: 0;
}

.nav-link-custom:hover {
  color: white !important; /* Cambia a blanco al pasar el mouse */
}

.nav-link-custom span {
  position: relative;
  z-index: 1;
}

/* Estilo para el submenú */
.navbar-custom .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco semi-transparente */
  border: none; /* Eliminar el borde */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Añadir una sombra suave */
  transition: background-color 0.3s ease;
}

/* Si quieres un fondo completamente sólido */
.navbar-custom .dropdown-menu.solid-background {
  background-color: #fff; /* Fondo blanco sólido */
}

/* Ajustes para el menú en dispositivos móviles */
@media (max-width: 768px) {
  .navbar-custom {
    height: auto; /* Ajusta la altura para dispositivos móviles */
  }

  .navbar-brand,
  .nav-link-custom {
    font-size: calc(1rem * 1.2); /* Ajusta el tamaño de la fuente en dispositivos móviles */
  }

  .nav-link-custom::before {
    background-color: red; /* Mantiene el color de fondo de la animación */
  }
}
