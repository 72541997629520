/* Sección principal */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white; /* Fondo blanco */
}

/* Estilo para la sección del logo */
.home-logo {
  position: relative;
  width: 100%;
  height: 500px; /* Mantener altura constante */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /* Fondo blanco */
  margin-bottom: 20px; /* Margen inferior para evitar solapamiento */
  overflow: hidden; /* Asegura que el contenido no se desborde */
  z-index: 1; /* Asegura que la sección de servicios esté por encima del logo */
}

/* Estilo para el GIF de humo */
.overlay-gif {
  position: absolute;
  width: 100%; /* Extender el GIF para cubrir todo el ancho */
  height: 100%; /* Ajusta a la altura del contenedor */
  opacity: 1;
  transition: opacity 1s ease-out; /* Transición para el desvanecimiento */
  z-index: 2; /* GIF delante del logo */
}

.overlay-gif.fade-out {
  opacity: 0;
}

/* Estilo para el logo */
.logo {
  height: 100%; /* Ajusta la altura al contenedor */
  transform: scale(1.5); /* Escala el logo sin cambiar la altura */
  position: relative;
  z-index: 1; /* Logo detrás del GIF y en la capa adecuada */
}

/* Estilo para la sección de servicios */
/* Estilo para la sección de servicios */
/* Estilo para la sección de servicios */
/* Estilo para la sección de servicios */
.services-container {
  width: 80%;
  margin: 0 auto; /* Ajuste automático del margen */
  padding-top: 20px; /* Ajusta el relleno superior para evitar solapamiento */
}

.services-title {
  text-align: center;
  margin-bottom: 20px;
}

/* Estilo para los paneles de servicio */
.service-panel {
  background-size: cover;
  background-position: center;
  color: white;
  margin-bottom: 20px;
  padding: 20px;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
  max-height: 60px;
  padding-bottom: 5px;
}

/* Hacer las imágenes más tenues */
.service-panel img {
  opacity: 0.01; /* Ajusta la opacidad para hacer las imágenes aún más transparentes */
  transition: opacity 0.3s ease; /* Transición para suavizar el cambio de opacidad */
}

/* Ajustar el estilo del contenido del panel */
.service-content {
  position: relative; /* Asegura que el contenido esté por encima de la imagen */
  margin-top: 20px;
  background: rgba(0, 0, 0, 0.7); /* Fondo negro con opacidad */
  padding: 10px;
  border-radius: 5px;
  max-height: 500px;
  overflow-y: auto;
}

.service-content ul {
  padding-left: 20px;
}

.service-panel.open {
  max-height: 600px;
  padding-bottom: 20px;
  overflow-y: auto;
}

.products-section {
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
}

.products {
  display: flex; /* Usar flexbox para disposición horizontal */
  flex-wrap: wrap; /* Permitir que los productos se envuelvan si es necesario */
  justify-content: center; /* Centrar productos horizontalmente */
}

.product {
  margin: 10px;
  cursor: pointer;
  width: 200px; /* Tamaño fijo para las imágenes */
  height: auto; /* Mantener la relación de aspecto */
}

/* Botón de "Ver todos los productos" centrado */
.view-all-button {
  display: block; /* Hacer que el botón ocupe su propio espacio en el flujo del documento */
  width: fit-content; /* Ajustar el ancho del botón al contenido */
  margin: 20px auto; /* Centrar horizontalmente y añadir espacio alrededor */
  text-align: center; /* Centrar el texto dentro del botón */
  cursor: pointer;
  color: #ffffff; /* Color del texto */
  background-color: #007bff; /* Color de fondo del botón */
  padding: 10px 20px; /* Espaciado interno del botón */
  border: none; /* Eliminar borde predeterminado */
  border-radius: 5px; /* Bordes redondeados */
  text-decoration: none; /* Eliminar subrayado del texto */
  font-size: 16px; /* Tamaño de fuente */
}

.view-all-button:hover {
  background-color: #0056b3; /* Cambiar color al pasar el ratón */
}


/* Estilo para la sección sobre la empresa */
.about-section {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0; /* Añadir relleno superior e inferior */
  text-align: center;
  margin-bottom: 60px; /* Añadir margen inferior para separar del footer */
}

/* Imagen en la sección sobre la empresa */
.about-section .about-image {
  width: 100%; /* Ajusta la imagen al contenedor */
  max-width: 600px; /* Limitar el tamaño máximo de la imagen */
  height: auto; /* Mantener la relación de aspecto */
  margin-bottom: 20px; /* Espacio debajo de la imagen */
}

/* Botón "Saber más" igual al de productos */
.about-section button {
  text-align: center;
  cursor: pointer;
  color: #007bff;
  padding: 10px 20px;
  border: 2px solid #007bff;
  border-radius: 5px;
  background-color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 16px; /* Tamaño de fuente ajustado */
}

.about-section button:hover {
  background-color: #007bff;
  color: white;
}
