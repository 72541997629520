.footer {
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    position: bottom;
    transition: height 0.5s ease, padding 0.5s ease;
    height: 50px;
    overflow: hidden;
    z-index: 8999;
}

.footer:hover {
    height: 170px; /* Aumenta la altura cuando se pasa el ratón */
    padding: 20px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.footer:hover .footer-content {
    opacity: 1;
}

.footer-section {
    flex: 1;
    margin: 10px;
}

.footer-section img {
    width: 30px;
    height: 30px;
    transition: opacity 0.5s ease;
}

.footer-section h4 {
    margin-bottom: 15px;
    font-size: 1.2em;
}

.footer-left img, .footer-right img {
    opacity: 1;
}

.footer-section p {
    margin: 0;
    font-size: 1em;
}

.footer-center img {
    width: 30px;
    height: 30px;
    margin: 0 10px;
    transition: transform 0.3s ease;
}

.footer-center img:hover {
    transform: scale(1.2);
}

.footer-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0; /* Ajusta esta propiedad para que coincida verticalmente con las imágenes del centro cuando el ratón está sobre el footer */
    left: 0;
    right: 0;
    height: 50px; /* Asegúrate de que este valor coincida con la altura del footer cuando el ratón no está sobre él */
    opacity: 1;
    transition: opacity 0.5s ease;
}

.footer:hover .footer-icons {
    opacity: 0;
}

.footer-icons div {
    display: flex;
    align-items: center;
}

.footer-icons img {
    width: 30px;
    height: 30px;
    margin: 0 10px;
}

.footer-icons p {
    color: white;
    font-size: 1em;
    margin: 0 10px;
}

.copyright {
    margin-bottom: 50px;
    font-size: 0.9em;
    opacity: 0.7;
}

.copyright a {
    color: white;
    text-decoration: none;
    margin-left: 10px;
    
}
@media (max-width: 768px) {
    .footer {
        height: 50px; /* Mantén la altura inicial en dispositivos móviles */
        padding: 10px;
        overflow: hidden; /* Asegúrate de que el contenido adicional no sea visible inicialmente */
        transition: height 0.5s ease, padding 0.5s ease;
    }
    .footer:hover {
        height: 370px; /* Aumenta la altura cuando se pasa el ratón */
        padding: 20px;
    }
    
    .footer:active, .footer:focus-within {
        height: 170px; /* Aumenta la altura cuando se hace clic en el footer */
        padding: 20px;
    }

    
    .footer-content {
        opacity: 0; /* Mantén el contenido oculto inicialmente */
        transition: opacity 0.5s ease;
    }

    .footer:active .footer-content, .footer:focus-within .footer-content {
        opacity: 1; /* Muestra el contenido al hacer clic */
    }

    .footer-icons {
        height: 50px; /* Ajusta la altura inicial de los iconos */
        transition: height 0.5s ease;
    }

    .footer:active .footer-icons, .footer:focus-within .footer-icons {
        opacity: 0; /* Oculta los íconos al hacer clic */
    }
}
